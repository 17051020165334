import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Waypoint } from 'react-waypoint';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoFeaturedItem,
  type DtoPage,
  type DtoPageRow,
  type DtoPageSection,
  EnumsPageName,
  EnumsPageRowLayout,
  EnumsPageSectionType,
} from '@lp-lib/api-service-client/public';

import { scrollToPageSection } from '../../../app/components/DynamicPage/PageSectionContainer';
import { PageSectionList } from '../../../app/components/DynamicPage/PageSectionList';
import { PageSectionNav } from '../../../app/components/DynamicPage/PageSectionNav';
import { pageSectionAnchor } from '../../../app/components/DynamicPage/utils';
import {
  FeaturedCard,
  FeaturedSlice,
} from '../../../app/components/Featured/FeaturedCard';
import { FeaturedList } from '../../../app/components/Featured/FeaturedList';
import { GamePackBreadcrumbs } from '../../../app/components/GamePack/GamePackBreadcrumbs';
import {
  GamePackCard,
  useHydratedNumPerRow,
} from '../../../app/components/GamePack/GamePackCollection';
import {
  type GameLikeFilterOptions,
  toGameLikeFilters,
} from '../../../app/components/GamePack/GamePackFilters';
import { PublicLibraryBreadcrumbs } from '../../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import {
  PublicLibraryFilter,
  PublicLibraryFilterKeysAllLoggedIn,
  PublicLibraryFilterKeysMain,
} from '../../../app/components/PublicLibrary/PublicLibraryFilter';
import {
  PublicLibraryGamePackCarousel,
  PublicLibraryGamePackCarouselLoading,
  PublicLibraryGamePackList,
  PublicLibraryGamePackListLoading,
  PublicLibraryTagCarousel,
  PublicLibraryTagList,
} from '../../../app/components/PublicLibrary/PublicLibraryLayout';
import { TagCard } from '../../../app/components/Tag/TagCard';
import { ErrorMessage } from '../../components/Game/GameCenter';
import { DefaultGamePackCardBadges } from '../../components/Game/GamePack/GamePackCard';
import { DoubleRightArrow } from '../../components/icons/Arrows';
import {
  PageManagerUtils,
  PageSectionUtils,
} from '../../components/PageManager/utils';
import { TagUtils } from '../../components/Tagging/utils';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Tag, TaggedObjectType } from '../../types';
import { type GamePack } from '../../types/game';
import { fromDTOGamePack, fromDtoTag } from '../../utils/api-dto';
import { assertExhaustive } from '../../utils/common';
import { getGridStyle } from '../../utils/css';
import { getGamePacksByTagId } from './Collections';
import { GamePackCardBottomAccessory } from './GamePackCardBottomAccessory';
import { GamePackTagDetails } from './GamePackTagDetails';

function useData(pageName: EnumsPageName) {
  return useSWRImmutable([`/embedded-public-library`, pageName], async () => {
    const [featuredItemsResp, pageResp] = await Promise.all([
      apiService.page.getFeatured({ size: 8 }),
      apiService.page.get(pageName),
    ]);
    const page = pageResp.data.page;
    page.sections = page.sections?.filter(
      (s) =>
        s.type === EnumsPageSectionType.PageSectionTypeGeneral &&
        s.rows &&
        s.rows.length > 0
    );

    return {
      featuredItems: featuredItemsResp.data.items,
      featuredRows: page.rows,
      page,
      gamePackTags: PageManagerUtils.GetGamePackTags(pageResp.data.page),
    };
  });
}

function EmbeddedPublicLibraryLayout(props: {
  page: DtoPage;
  children: React.ReactNode;
  isSelectedSection?: (section: DtoPageSection) => boolean;
  onSectionClick: (section: DtoPageSection) => void;
}) {
  const { page, children, isSelectedSection, onSectionClick } = props;

  return (
    <div className='w-full h-full text-white flex flex-col'>
      {!!page.sections && page.sections.length > 1 && (
        <div
          className='
            w-full h-15 flex-none
            px-5
          bg-modal border-b border-secondary  
            flex justify-between items-center gap-10
          '
        >
          <PageSectionNav
            sections={page.sections || []}
            selectedSection={
              isSelectedSection ? page.sections.find(isSelectedSection) : null
            }
            onSectionClick={onSectionClick}
          />
        </div>
      )}

      <div className='w-full flex-1 overflow-auto scrollbar'>{children}</div>
    </div>
  );
}

function EmbeddedGamePackCard(props: { pack: GamePack; onClick: () => void }) {
  const { pack, onClick } = props;

  return (
    <GamePackCard
      gamePack={pack}
      onClick={onClick}
      badges={<DefaultGamePackCardBadges gamePack={pack} />}
      bottomAccessory={<GamePackCardBottomAccessory pack={pack} />}
      styles={{
        size: 'w-full',
        badgesTranslateClassName: '-translate-y-1/4',
      }}
      showVersion={false}
    />
  );
}

function EmbeddedPublicLibrarySearch(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  filterOptions: Partial<GameLikeFilterOptions>;
  onApply: (options: Partial<GameLikeFilterOptions>) => void;
  onShowIndex: (section?: DtoPageSection) => void;
  onClickGamePack: (pack: GamePack) => void;
}) {
  const {
    pageName,
    page,
    filterOptions,
    onApply,
    onShowIndex,
    onClickGamePack,
  } = props;

  const { data: packs = [] } = useSWR(
    ['/embedded-public-library-search', filterOptions],
    async () => {
      const packs = await apiService.gamePack
        .searchGamePacks('', {
          filterParams: toGameLikeFilters(filterOptions),
          primeOnly: true,
          scope: pageName,
        })
        .next();
      return packs;
    }
  );

  const numPerRow = useHydratedNumPerRow();
  const [filters, setFilters] =
    useState<Partial<GameLikeFilterOptions>>(filterOptions);

  return (
    <EmbeddedPublicLibraryLayout
      page={page}
      onSectionClick={(section) => onShowIndex(section)}
    >
      <div className='w-full p-10 flex flex-col gap-10'>
        <GamePackBreadcrumbs
          items={[
            { label: 'Explore', onClick: onShowIndex },
            { label: 'Matched Experiences' },
          ]}
        />

        <div className='w-full flex justify-center'>
          <PublicLibraryFilter
            showMenuKeys={PublicLibraryFilterKeysAllLoggedIn}
            filterOptions={filters}
            onChange={setFilters}
            onApply={onApply}
          />
        </div>

        {packs.length > 0 ? (
          <div
            className={`w-full grid gap-y-12 gap-x-3 pt-4 ${
              numPerRow > 0 ? 'visible' : 'invisible'
            }`}
            style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
          >
            {packs.map((p) => (
              <div className='pt-5 px-2' key={p.id}>
                <EmbeddedGamePackCard
                  pack={p}
                  onClick={() => onClickGamePack(p)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className='text-secondary text-center'>
            No Matching Experiences Found
          </div>
        )}
      </div>
    </EmbeddedPublicLibraryLayout>
  );
}

function EmbeddedPublicLibraryTagDetails(props: {
  page: DtoPage;
  tags: Tag[];
  tag: Tag;
  onShowIndex: (section?: DtoPageSection) => void;
  onShowTagDetails: (tag: Tag) => void;
  onGamePackClick: (pack: GamePack) => void;
}) {
  const { page, tags, tag, onShowIndex, onShowTagDetails, onGamePackClick } =
    props;

  return (
    <EmbeddedPublicLibraryLayout
      page={page}
      isSelectedSection={(section) => PageSectionUtils.HasTag(section, tag.id)}
      onSectionClick={onShowIndex}
    >
      <GamePackTagDetails
        tags={tags}
        tag={tag}
        personalized
        excludeTemplates
        breadcrumb={
          <PublicLibraryBreadcrumbs
            items={[
              {
                kind: 'button',
                label: 'Explore',
                onClick: () => onShowIndex(),
              },
              {
                kind: 'noop',
                label: tag.name,
              },
            ]}
          />
        }
        onViewTag={(tag) => onShowTagDetails(tag)}
        onGamePackClick={onGamePackClick}
      />
    </EmbeddedPublicLibraryLayout>
  );
}

function EmbeddedPublicLibraryFeaturedTag(props: {
  pageName: EnumsPageName;
  tag: Tag;
  onShowTagDetails: (tag: Tag) => void;
  onGamePackClick: (pack: GamePack) => void;
  row: DtoPageRow;
}) {
  const { pageName, tag, onShowTagDetails, row, onGamePackClick } = props;

  const [entered, setEntered] = useState(false);
  const { data: packs, mutate } = useSWR(
    entered ? ['/game-packs', tag.id] : null,
    async () => await getGamePacksByTagId(tag.id, true, true),
    {
      revalidateOnFocus: false,
    }
  );

  const showHeader =
    pageName !== EnumsPageName.PageNameLiveOtp &&
    pageName !== EnumsPageName.PageNameLiveLoggedIn;

  return (
    <div className='w-full'>
      <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll></Waypoint>

      {showHeader && (
        <div className='flex items-center gap-8 group'>
          <button
            type='button'
            onClick={() => onShowTagDetails(tag)}
            className='btn flex items-center gap-8'
          >
            <p className={`text-xl font-bold`}>{tag.name}</p>
            <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
              <p className='text-sms'>View All</p> <DoubleRightArrow />
            </div>
          </button>
        </div>
      )}

      {!packs &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackListLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ) : (
          <PublicLibraryGamePackCarouselLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ))}
      {packs && packs.length === 0 && (
        <ErrorMessage text={`No game packs found`} handleRetry={() => mutate} />
      )}
      {packs &&
        packs.length > 0 &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackList
            packs={packs || []}
            renderCard={(pack) => (
              <div className='pt-5 px-1' key={pack.id}>
                <EmbeddedGamePackCard
                  pack={pack}
                  onClick={() => onGamePackClick(pack)}
                />
              </div>
            )}
          />
        ) : (
          <PublicLibraryGamePackCarousel
            packs={packs || []}
            renderCard={(pack) => (
              <div className='pt-5 px-1' key={pack.id}>
                <EmbeddedGamePackCard
                  pack={pack}
                  onClick={() => onGamePackClick(pack)}
                />
              </div>
            )}
          />
        ))}
    </div>
  );
}

function EmbeddedPublicLibraryIndex(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  featuredItems: DtoFeaturedItem[];
  initialSection?: DtoPageSection | null;
  onShowSearch: (filterOptions: Partial<GameLikeFilterOptions>) => void;
  onShowTagDetails: (tag: Tag) => void;
  onGamePackClick: (pack: GamePack) => void;
}) {
  const {
    pageName,
    page,
    featuredItems,
    initialSection,
    onShowSearch,
    onShowTagDetails,
    onGamePackClick,
  } = props;

  const [filters, setFilters] = useState<Partial<GameLikeFilterOptions>>({});

  const [focusedSection, setFocusedSection] = useState<DtoPageSection | null>(
    () => (page.sections && page.sections.length > 0 ? page.sections[0] : null)
  );

  useEffectOnce(() => {
    if (initialSection) {
      const element = document.getElementById(
        pageSectionAnchor(initialSection)
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  });

  const showHeader =
    pageName !== EnumsPageName.PageNameLiveOtp &&
    pageName !== EnumsPageName.PageNameLiveLoggedIn;

  return (
    <EmbeddedPublicLibraryLayout
      page={page}
      isSelectedSection={(section) => focusedSection?.id === section.id}
      onSectionClick={(section) => scrollToPageSection(section)}
    >
      <PageSectionList
        pageName={pageName}
        sections={page.sections || []}
        onFocusedSectionChange={(section) => setFocusedSection(section)}
        renderPrograms={() => <></>}
        renderFeaturedCarousel={() => (
          <div className='w-full flex flex-col items-center gap-5'>
            <div className='w-full max-w-[1380px]'>
              <FeaturedList
                hideBullets
                items={featuredItems}
                renderSlide={(props) => <FeaturedSlice {...props} />}
                renderCard={(props) => (
                  <FeaturedCard
                    {...props}
                    onGamePackClick={(pack) =>
                      onGamePackClick(fromDTOGamePack(pack))
                    }
                    onTagClick={(tag) => onShowTagDetails(fromDtoTag(tag))}
                  />
                )}
              />
            </div>

            <PublicLibraryFilter
              showMenuKeys={PublicLibraryFilterKeysMain}
              filterOptions={filters}
              withApplyButton
              onChange={setFilters}
              onApply={() => onShowSearch(filters)}
            />
          </div>
        )}
        renderFeaturedTag={({ tag, row }) => (
          <EmbeddedPublicLibraryFeaturedTag
            pageName={pageName}
            tag={tag}
            onShowTagDetails={onShowTagDetails}
            onGamePackClick={onGamePackClick}
            row={row}
          />
        )}
        renderTagCollection={({ collection, row }) => (
          <div className='w-full'>
            {showHeader && (
              <h3 className={`text-xl font-bold`}>{collection.name}</h3>
            )}

            {row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
              <PublicLibraryTagList
                tags={collection.tags || []}
                renderCard={(tag) => (
                  <TagCard tag={tag} onClick={() => onShowTagDetails(tag)} />
                )}
              />
            ) : (
              <PublicLibraryTagCarousel
                tags={collection.tags || []}
                renderCard={(tag) => (
                  <TagCard tag={tag} onClick={() => onShowTagDetails(tag)} />
                )}
              />
            )}
          </div>
        )}
      />
    </EmbeddedPublicLibraryLayout>
  );
}

type EmbeddedPublicLibraryRoute =
  | {
      target: 'index';
      focusedSection?: DtoPageSection | null;
    }
  | {
      target: 'tag-details';
      tag: Tag;
    }
  | {
      target: 'search';
      filterOptions: Partial<GameLikeFilterOptions>;
    };

function EmbeddedPublicLibrarySection(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  featuredItems: DtoFeaturedItem[];
  gamePackTags: Tag[];
  onGamePackClick: (pack: GamePack) => void;
  initialRoute?: EmbeddedPublicLibraryRoute;
}) {
  const { onGamePackClick, page, pageName, featuredItems, gamePackTags } =
    props;

  const [route, setRoute] = useState<EmbeddedPublicLibraryRoute>(
    props.initialRoute ?? {
      target: 'index',
    }
  );

  const target = route.target;
  switch (target) {
    case 'index':
      return (
        <EmbeddedPublicLibraryIndex
          pageName={pageName}
          page={page}
          featuredItems={featuredItems}
          initialSection={route.focusedSection}
          onShowSearch={(filterOptions) =>
            setRoute({ target: 'search', filterOptions })
          }
          onShowTagDetails={(tag) => setRoute({ target: 'tag-details', tag })}
          onGamePackClick={onGamePackClick}
        />
      );
    case 'tag-details':
      return (
        <EmbeddedPublicLibraryTagDetails
          page={page}
          tags={gamePackTags}
          tag={route.tag}
          onShowIndex={(section) =>
            setRoute({ target: 'index', focusedSection: section })
          }
          onShowTagDetails={(tag) => setRoute({ target: 'tag-details', tag })}
          onGamePackClick={onGamePackClick}
        />
      );
    case 'search':
      return (
        <EmbeddedPublicLibrarySearch
          pageName={pageName}
          page={page}
          filterOptions={route.filterOptions}
          onApply={(options) =>
            setRoute({ target: 'search', filterOptions: options })
          }
          onShowIndex={() =>
            setRoute({ target: 'index', focusedSection: null })
          }
          onClickGamePack={onGamePackClick}
        />
      );
    default:
      assertExhaustive(target);
      return null;
  }
}

function defaultPageName() {
  const pageName = getQueryParam('public-library-page-name');
  if (PageManagerUtils.IsPageName(pageName)) {
    return pageName;
  }
  console.log('Invalid page name:', pageName);
  return EnumsPageName.PageNamePublicHome;
}

export function EmbeddedPublicLibrary(props: {
  pageName?: EnumsPageName;
  onGamePackClick: (pack: GamePack) => void;
  initialRoute?: EmbeddedPublicLibraryRoute;
}) {
  const { pageName = defaultPageName(), onGamePackClick } = props;

  const { isLoading, data } = useData(pageName);
  if (isLoading || !data) return null;

  return (
    <EmbeddedPublicLibrarySection
      pageName={pageName}
      page={data.page}
      onGamePackClick={onGamePackClick}
      gamePackTags={data?.gamePackTags || []}
      featuredItems={data?.featuredItems || []}
      initialRoute={props.initialRoute}
    />
  );
}
